<template>
    <v-app>
        <div id="page-not-found">
            <v-container class="pa-8">
                <h1 class="mb-12">
                    <span class="not-found-code">404</span><br />
                    <span class="not-found-headline">...wo ist denn unser Lager?</span>
                </h1>

                <v-btn to="/" depressed color="primary" class="py-5 px-9">Zurück zur Startseite</v-btn>
            </v-container>
        </div>
    </v-app>
</template>

<script>
export default {
    name: "PageNotFound",
};
</script>

<style lang="sass">
#page-not-found
    height: 100vh
    background: url("../assets/404-camp-site.jpg") center center no-repeat
    background-size: cover
    text-align: right

    .not-found-code
        font-size: 8rem
        font-weight: 900
        line-height: 7rem

    .not-found-headline
        font-size: 3rem
        font-weight: 700
</style>
